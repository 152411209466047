import { Injectable, OnInit } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';
import { Configuration } from '../interfaces/settings.interface';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  configuration!: Configuration;
  
  constructor(private http:HttpBaseService, private storageService: StorageService) { 
    if(!this.configuration){
      this.configuration = this.storageService.getLocalStorageItem('configuration');
    }
  }

  async getConfiguration(){
    this.configuration = await lastValueFrom(this.http.get('configurations'));
    this.storageService.setLocalStorageItem('configuration', this.configuration)
  }

  getPwcFlag(){
    if(!this.configuration){
      this.configuration = this.storageService.getLocalStorageItem('configuration')
    }
    return this.configuration.pwcEnabled.enabled || false;
  }

  getMultiTenantFlag(){
    return this.configuration.multiTenant.enabled || false;
  }
}
